.footer-wrap {
  background-image: url("./bg1.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 30px 20px;
  position: relative;
  isolation: isolate;
}
.footer-wrap::before {
  content: "";
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -4;
  opacity: 0.8;
}
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 60px;
}
footer > img {
  width: 324px;
  height: 240px;
  object-fit: contain;
}
.foot-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}
.foot-links a {
  text-decoration: none;
  color: white;
  font-weight: 400;
  font-size: 24px;
}
.social-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.social-footer h3 {
  color: var(--light-green);
  font-weight: 700;
  font-size: 32px;
}
.foot-social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.foot-social-links a {
  font-size: 35px;
  color: rgba(255, 255, 255, 0.3);
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}
.foot-social-links a:hover {
  color: white;
}
.copy-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #090909;
  padding-inline: 20px;
  height: 83.25px;
  box-shadow: inset 0px 3px 22.5px rgba(0, 0, 0, 0.5);
}
.copy-div > div {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.copy-div p {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: var(--light-green);
}
.privacy-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.privacy-div a {
  color: var(--light-green);
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}
@media screen and (max-width: 825px) {
  footer {
    gap: 40px;
  }
  footer > img {
    width: 150px;
    height: auto;
  }
  .foot-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    gap: 40px;
  }
  .social-footer h3 {
    font-size: 20px;
    line-height: 19px;
  }
  .copy-div {
    height: auto;
    padding: 20px 20px;
  }
  .copy-div div {
    flex-direction: column;
    gap: 10px;
  }
  .foot-social-links a {
    font-size: 25px;
  }
}
