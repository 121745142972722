.transparent {
  border: 2px solid var(--light-green);
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: var(--light-green);
  cursor: pointer;
  background-color: transparent;
  text-decoration: none;
  display: grid;
  transition: all 0.2s ease-in-out;
  place-items: center;
}
.transparent:hover {
  background-color: var(--light-green);
  color: white;
}
.cta-btn {
  display: grid;
  cursor: pointer;
  place-items: center;
  background-color: transparent;
  text-decoration: none;
  display: grid;
  place-items: center;
  border: 2px solid var(--light-green);
}
.cta-btn > div {
  background-color: var(--light-green);
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  color: white;
  font-weight: 500;
  font-size: 18px;
  transition: all 0.2s linear;
}
.cta-btn:hover > div {
  background-color: transparent;
  color: var(--light-green);
}
