.roadmap-wrapp {
  padding: 180px 20px;
  /* margin-top: 100px; */
  align-items: flex-end;
  background-image: url("./bg/roadmap-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  isolation: isolate;
}
.roadmap-wrapp::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.8;
  z-index: -1;
}
.road-line {
  position: absolute;
  left: 0;
  object-fit: cover;
  height: 40px;
  width: 100%;
}
.road-line1 {
  top: 0%;
}
.road-line2 {
  bottom: 0;
}
.roadmap-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  max-width: calc(100% - 150px);
  overflow-x: auto;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
.roadmap-div {
  scrollbar-width: thin;
  scrollbar-color: #df9200 #0c0a08;
  padding-bottom: 20px;
}

/* Chrome, Edge, and Safari */
.roadmap-div::-webkit-scrollbar {
  width: 10px;
  height: 7px;
}

.roadmap-div::-webkit-scrollbar-track {
  background: #0c0a08;
}

.roadmap-div::-webkit-scrollbar-thumb {
  background-color: var(--light-green);
  border-radius: 8px;
  border: 0px none #ffffff;
}
.roadmap-grid {
  display: grid;
  grid-template-columns: 30px 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
  width: 100%;
  row-gap: 120px;
  max-width: 100%;
  column-gap: 120px;
  margin-top: 100px;
}
.roadmap-grid .line-roadmap {
  height: 3px;
  width: 100%;
  background-color: rgba(223, 146, 0, 0.2);
  grid-column: 1/5;
  grid-row: 2/3;
  position: relative;
}
.line-roadmap::before {
  content: "";
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  width: 14px;
  height: 14px;
  z-index: 20;
  background-color: var(--light-green);
}
.active-line-road {
  background-color: var(--light-green);
  width: 70%;
  height: 3px;
  grid-column: 1/3;
  grid-row: 2/3;
  margin-left: 10px;
  position: relative;
}
.pointer {
  position: absolute;
  right: 0;
  top: 50%;
  transform-origin: center;
  transform: translateY(-50%);
  max-width: 100px;
  opacity: 0.9;
}
.road-box {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 50px;
  height: max-content;
  min-width: 527px;
  background: rgba(255, 255, 255, 0.1);
}
.road-box ul {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  list-style-position: inside;
}
.road-box li {
  color: white;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
}
.roadbox-1 {
  grid-column: 2/3;
  grid-row: 3/4;
}
.roadbox-2 {
  grid-column: 3/4;
  grid-row: 3/4;
}
.roadbox-3 {
  grid-column: 4/5;
  grid-row: 3/4;
}

.roadmap-grid > h3 {
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  color: var(--light-green);
  justify-self: center;
  align-self: center;
  opacity: 0.4;
}
.roadmap-grid .active-title {
  opacity: 1;
}
.road-title-1 {
  grid-column: 2/3;
  grid-row: 1/2;
}
.road-title-2 {
  grid-column: 3/4;
  grid-row: 1/2;
}
.road-title-3 {
  grid-column: 4/5;
  grid-row: 1/2;
}
@media screen and (max-width: 900px) {
  .roadmap-wrapp {
    /* margin-top: 50px; */
    padding: 150px 20px;
  }
  .road-line {
    height: 30px;
  }
  .roadmap-div {
    max-width: 100%;
    align-items: center;
  }
  .roadmap-div > h2 {
    font-size: 32px;
    line-height: 38px;
  }

  .roadmap-grid {
    margin-top: 50px;
    row-gap: 100px;
    grid-template-columns: 100% 1fr 1fr;
  }
  .active-line-road {
    grid-column: 1/2;
    width: 40%;
  }
  .pointer {
    left: 100%;
    width: 80px;
    object-fit: contain;
  }
  .road-title-1 {
    grid-column: 1/2;
  }
  .road-box {
    justify-self: center;
    align-self: center;
    padding: 20px;
    min-width: 350px;
  }
  .roadbox-1,
  .road-title-1 {
    grid-column: 1/2;
  }
  .roadbox-2,
  .road-title-2 {
    grid-column: 2/3;
  }
  .roadbox-3,
  .road-title-3 {
    grid-column: 3/4;
  }
  .road-box ul li {
    font-size: 18px;
    line-height: 21px;
  }
  .roadmap-grid > h3 {
    font-size: 24px;
    line-height: 29px;
  }
}
