.hero-wrap {
  background-image: url("./bg/bg2.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* margin-bottom: 35px; */
  position: relative;
  isolation: isolate;
}
.hero-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: -1;
}
.hero-div {
  width: 100%;
  padding: 100px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  min-height: 100vh;
}
.hero-div > img {
  max-width: 350.87px;
  width: 100%;
  object-fit: contain;
}
.hero-div > p {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  max-width: 800px;
}
.hero-div > p:last-child {
  font-size: 16px;
}
.hero-div > p span {
  color: var(--light-green);
  font-size: 28px;
  font-weight: bold;
  line-height: 1.4;
}
.btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}
.social-hero {
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
  margin-top: 10px;
}
.social-hero > a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: white;
  transition: all 0.7s ease-in-out;
}
.social-hero > a:hover {
  color: var(--light-green);
}
/* @media screen and (max-width: 1161px) {
  .hero-wrap {
    margin-bottom: 30px;
  }
} */
@media screen and (max-width: 650px) {
  .hero-div > img {
    max-width: 190px;
  }
  .hero-div > p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 610px) {
  .hero-div .btn-div {
    flex-direction: column;
    width: 100%;
  }
  .hero-div .btn-div button,
  .hero-div .btn-div a {
    width: 100% !important;
  }
}
