.header-wrapp {
  transition: all 0.4s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 85;
}
.header-wrapp header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: var(--max-width);
  padding: 0px 20px;
  height: 120px;
}

.inner-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left-header,
.right-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.left-header {
  gap: 60px;
  width: 100%;
}
.left-header img {
  width: 75px;
  object-fit: contain;
}
nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
nav a,
.dash-link {
  color: white;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  transition: all 0.5s ease-in;
  text-decoration: none;
  cursor: pointer;
}

nav .active-link,
.dash-link {
  color: var(--light-green);
  font-weight: 600;
}
.dash-link:hover {
  color: white;
}
.active-link:before {
  content: "";
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: var(--light-green);
  width: 6px;
  height: 6px;
}
nav a:hover {
  color: var(--light-green);
}
.right-header {
  gap: 20px;
}
.right-header > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}
.sign-up-btn {
  color: var(--light-green);
  cursor: pointer;
  text-decoration: none;
}
.right-header svg {
  color: white;
  font-size: 20px;
  transition: all 0.5s ease-in-out;
}
.right-header svg:hover {
  color: var(--light-green);
}
.left-header > svg {
  display: none;
}
.right-header a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.mob-dash-link {
  display: none;
}
@media screen and (max-width: 1085px) {
  .left-header > svg {
    display: block;
    position: relative;
    z-index: 85;
    cursor: pointer;
    font-size: 25px;
  }
  .left-header > img {
    position: relative;
    z-index: 85;
    width: 60.75px;
  }
  header {
    height: 80px;
  }
  .left-header {
    justify-content: space-between;
  }
  .inner-header {
    position: fixed;
    top: 0;
    right: -1000px;
    z-index: 82;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #0c0a08;
    width: 100%;
    max-width: 500px;
    height: 100%;
    gap: 30px;
    transition: all 1s ease-in-out;
    padding-top: 90px;
    padding-inline: 20px;
    padding-bottom: 20px;
    overflow-y: auto;
  }
  .active-nav {
    right: 0;
  }
  .left-header nav {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    text-align: left;
    gap: 0px;
  }
  nav a:first-of-type {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .dash-link {
    display: none !important;
  }
  .mob-dash-link {
    display: flex;
  }
  nav a {
    padding: 20px 0px;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .active-link::before {
    display: none;
  }
  .right-header {
    width: 100%;
    gap: 30px;
    flex-direction: column-reverse;
  }

  .right-header > div button {
    width: 100% !important;
  }
  .overlay {
    z-index: 80;
    background-color: black;
    opacity: 0.7;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .right-header > div {
    gap: 40px;
    flex-direction: column;
  }
  .sign-div > a {
    width: 100%;
  }
  .sign-div {
    width: 100%;
  }
}
@media screen and (max-width: 520px) {
  .inner-header {
    max-width: 100%;
  }
}
