.faq-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  padding: 100px 20px;
  max-width: var(--max-width);
  gap: 40px;
  margin-top: 20px;
}

.faqs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  background: #110f06;
  border: 0.75px solid var(--light-green);
  border-radius: 6px;
  padding: 20px 30px;
  gap: 30px;
}

@media screen and (max-width: 650px) {
  .faq-div {
    margin-top: 0px;
    padding: 50px 20px;
  }
  .faqs {
    padding: 35px 20px;
    gap: 18px;
  }
}
