@import url(https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@font-face {
  font-family: Alte;
  src: url(/static/media/AlteHaasGroteskBold.d18dfb36.woff);
  font-weight: 700;
}
@font-face {
  font-family: Alte;
  src: url(/static/media/AlteHaasGroteskRegular.2976eca8.woff);
  font-weight: 400;
}
@font-face {
  font-family: Petita;
  src: url(/static/media/PetitaBold.f3d25345.woff);
  font-weight: 700;
}
@font-face {
  font-family: Petita;
  src: url(/static/media/PetitaLight.9cd5ca5b.woff);
  font-weight: 300;
}
@font-face {
  font-family: Petita;
  src: url(/static/media/PetitaMedium.cee23c21.woff);
  font-weight: 500;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Petita", sans-serif !important;
}
:root {
  --dark-green: #002e14;
  --light-green: #009a44;
  --max-width: 1145px;
}
.alte {
  font-family: "Alte", Courier, monospace !important;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
body {
  background: #0c0a08;
  display: initial !important;
  margin: 0 !important;
}
html {
  scroll-behavior: smooth;
  scroll-padding-block: 50px;
}
.bg {
  background-image: url(/static/media/mint.ef2d109d.webp);
  width: 100%;
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  position: relative;
  isolation: isolate;
  padding-top: 20px;
}
.bg::before {
  content: "";
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -4;
  opacity: 0.9;
}
.MuiPaper-elevation3 {
  box-shadow: none !important;
}

/* Customize your main colors in :root variables
:root {
  --main-background-color: #343A50;
  --card-background-color: #fcfcfc;
  --countdown-background-color: #433765;
  --main-text-color:#999792;
  --title-text-color:#009a44;
}

body{
/*  background-color:var(--main-background-color);*/
/* background: url("img/background.png");
  background-size: cover;
  font-family: 'Petita', ;
  font-size: 1.1em;
}
	

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
color:#0b1347
} */

.transparent {
  border: 2px solid var(--light-green);
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: var(--light-green);
  cursor: pointer;
  background-color: transparent;
  text-decoration: none;
  display: grid;
  transition: all 0.2s ease-in-out;
  place-items: center;
}
.transparent:hover {
  background-color: var(--light-green);
  color: white;
}
.cta-btn {
  display: grid;
  cursor: pointer;
  place-items: center;
  background-color: transparent;
  text-decoration: none;
  display: grid;
  place-items: center;
  border: 2px solid var(--light-green);
}
.cta-btn > div {
  background-color: var(--light-green);
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  color: white;
  font-weight: 500;
  font-size: 18px;
  transition: all 0.2s linear;
}
.cta-btn:hover > div {
  background-color: transparent;
  color: var(--light-green);
}

.header-wrapp {
  transition: all 0.4s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 85;
}
.header-wrapp header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: var(--max-width);
  padding: 0px 20px;
  height: 120px;
}

.inner-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left-header,
.right-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.left-header {
  grid-gap: 60px;
  gap: 60px;
  width: 100%;
}
.left-header img {
  width: 75px;
  object-fit: contain;
}
nav {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
}
nav a,
.dash-link {
  color: white;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  transition: all 0.5s ease-in;
  text-decoration: none;
  cursor: pointer;
}

nav .active-link,
.dash-link {
  color: var(--light-green);
  font-weight: 600;
}
.dash-link:hover {
  color: white;
}
.active-link:before {
  content: "";
  position: absolute;
  top: 120%;
  left: 50%;
  -webkit-transform: translate(-50%, 50%) rotate(45deg);
          transform: translate(-50%, 50%) rotate(45deg);
  background-color: var(--light-green);
  width: 6px;
  height: 6px;
}
nav a:hover {
  color: var(--light-green);
}
.right-header {
  grid-gap: 20px;
  gap: 20px;
}
.right-header > div {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}
.sign-up-btn {
  color: var(--light-green);
  cursor: pointer;
  text-decoration: none;
}
.right-header svg {
  color: white;
  font-size: 20px;
  transition: all 0.5s ease-in-out;
}
.right-header svg:hover {
  color: var(--light-green);
}
.left-header > svg {
  display: none;
}
.right-header a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign-div {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.mob-dash-link {
  display: none;
}
@media screen and (max-width: 1085px) {
  .left-header > svg {
    display: block;
    position: relative;
    z-index: 85;
    cursor: pointer;
    font-size: 25px;
  }
  .left-header > img {
    position: relative;
    z-index: 85;
    width: 60.75px;
  }
  header {
    height: 80px;
  }
  .left-header {
    justify-content: space-between;
  }
  .inner-header {
    position: fixed;
    top: 0;
    right: -1000px;
    z-index: 82;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #0c0a08;
    width: 100%;
    max-width: 500px;
    height: 100%;
    grid-gap: 30px;
    gap: 30px;
    transition: all 1s ease-in-out;
    padding-top: 90px;
    padding-inline: 20px;
    padding-bottom: 20px;
    overflow-y: auto;
  }
  .active-nav {
    right: 0;
  }
  .left-header nav {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    text-align: left;
    grid-gap: 0px;
    gap: 0px;
  }
  nav a:first-of-type {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .dash-link {
    display: none !important;
  }
  .mob-dash-link {
    display: flex;
  }
  nav a {
    padding: 20px 0px;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .active-link::before {
    display: none;
  }
  .right-header {
    width: 100%;
    grid-gap: 30px;
    gap: 30px;
    flex-direction: column-reverse;
  }

  .right-header > div button {
    width: 100% !important;
  }
  .overlay {
    z-index: 80;
    background-color: black;
    opacity: 0.7;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .right-header > div {
    grid-gap: 40px;
    gap: 40px;
    flex-direction: column;
  }
  .sign-div > a {
    width: 100%;
  }
  .sign-div {
    width: 100%;
  }
}
@media screen and (max-width: 520px) {
  .inner-header {
    max-width: 100%;
  }
}

.footer-wrap {
  background-image: url(/static/media/bg1.9a17ffd3.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 30px 20px;
  position: relative;
  isolation: isolate;
}
.footer-wrap::before {
  content: "";
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -4;
  opacity: 0.8;
}
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 60px;
  gap: 60px;
}
footer > img {
  width: 324px;
  height: 240px;
  object-fit: contain;
}
.foot-links {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 25px;
  gap: 25px;
}
.foot-links a {
  text-decoration: none;
  color: white;
  font-weight: 400;
  font-size: 24px;
}
.social-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}
.social-footer h3 {
  color: var(--light-green);
  font-weight: 700;
  font-size: 32px;
}
.foot-social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.foot-social-links a {
  font-size: 35px;
  color: rgba(255, 255, 255, 0.3);
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}
.foot-social-links a:hover {
  color: white;
}
.copy-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #090909;
  padding-inline: 20px;
  height: 83.25px;
  box-shadow: inset 0px 3px 22.5px rgba(0, 0, 0, 0.5);
}
.copy-div > div {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.copy-div p {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: var(--light-green);
}
.privacy-div {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.privacy-div a {
  color: var(--light-green);
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}
@media screen and (max-width: 825px) {
  footer {
    grid-gap: 40px;
    gap: 40px;
  }
  footer > img {
    width: 150px;
    height: auto;
  }
  .foot-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    grid-gap: 40px;
    gap: 40px;
  }
  .social-footer h3 {
    font-size: 20px;
    line-height: 19px;
  }
  .copy-div {
    height: auto;
    padding: 20px 20px;
  }
  .copy-div div {
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }
  .foot-social-links a {
    font-size: 25px;
  }
}

.about-wrapp {
  position: relative;
  isolation: isolate;
}
.about-wrapp::before {
  content: "";
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -4;
  opacity: 0.8;
}
.about-div a {
  text-decoration: none;
  width: 100%;
}
.about-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: var(--max-width);
  padding-inline: 20px;
  padding-top: 60px;
  grid-gap: 40px;
  gap: 40px;
}
.left-about {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  min-width: 500px;
  text-align: left;
}
.left-about > h2 {
  color: white;
  font-weight: 700;
  font-size: 76px;
  line-height: 125%;
}
.left-about p {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: white;
  max-width: 650px;
}
.about-div > img {
  max-height: 800px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.line {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 35px;
  background-image: url(/static/media/line.96328169.png);
  background-repeat: repeat-x;
}
.line1 {
  bottom: 100%;
}
.line2 {
  top: 100%;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.about-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  z-index: -5;
}

@media screen and (max-width: 1300px) {
  .about-div > img {
    max-height: 600px;
  }
}
@media screen and (max-width: 1161px) {
  .about-div {
    flex-direction: column;
  }

  .left-about {
    align-items: center;
    min-width: auto;
    text-align: center;
  }
  .left-about > h2 {
    font-size: 38px;
  }
  .left-about > p {
    font-size: 14px;
    line-height: 21px;
  }
  .about-div > img {
    max-height: 420px;
  }
  .circle {
    right: 50%;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
    max-width: 80%;
  }
  .triangle {
    display: none;
  }
  .mob-triangle {
    max-width: 100%;
    object-fit: cover;
    left: 50%;
    bottom: 0%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    position: absolute;
    z-index: -2;
    height: 450px;
    display: block;
  }
  .line {
    height: 30px;
  }
}
@media screen and (max-width: 480px) {
  .left-about .btn-div {
    flex-direction: column;
    width: 100%;
  }
  .left-about .btn-div button {
    width: 100% !important;
  }
}

.blockchain-div {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.blockchain-row {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: stretch;
}
.blockchain-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center;
  grid-gap: 20px;
  gap: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 60px;
  width: 100%;
  position: relative;
  isolation: isolate;
}
.blockchain-box::before {
  content: "";
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -4;
  opacity: 0.8;
}
.blockchain-box:first-of-type {
  background-image: url(/static/media/defi-bg.f417d5e9.webp);
}
.blockchain-box:last-of-type {
  background-image: url(/static/media/ownership-bg.28f25a99.webp);
}
.blockchain-box h3 {
  color: var(--light-green);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
}
.blockchain-box p {
  color: white;
  font-weight: 300;
  max-width: 600px;
  font-size: 22px;
  line-height: 33px;
}
@media screen and (max-width: 1220px) {
  .blockchain-box {
    padding-inline: 30px;
  }
}
@media screen and (max-width: 1000px) {
  .blockchain-row {
    flex-direction: column-reverse;
    margin-top: 50px;
  }
  .blockchain-box {
    padding: 30px 20px;
  }
}
@media screen and (max-width: 650px) {
  .blockchain-div {
    margin-top: 40px;
  }
  .blockchain-box h3 {
    font-size: 24px;
    line-height: 29px;
  }
  .blockchain-box p {
    font-size: 14px;
    line-height: 21px;
  }
  .blockchain-box {
    padding: 50px 20px;
  }
}

.faq-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  padding: 100px 20px;
  max-width: var(--max-width);
  grid-gap: 40px;
  gap: 40px;
  margin-top: 20px;
}

.faqs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  background: #110f06;
  border: 0.75px solid var(--light-green);
  border-radius: 6px;
  padding: 20px 30px;
  grid-gap: 30px;
  gap: 30px;
}

@media screen and (max-width: 650px) {
  .faq-div {
    margin-top: 0px;
    padding: 50px 20px;
  }
  .faqs {
    padding: 35px 20px;
    grid-gap: 18px;
    gap: 18px;
  }
}

.faq-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  margin: 4px 0px;
}
.faq-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 18px 0;
  cursor: pointer;
  color: white;
  grid-gap: 20px;
  gap: 20px;
}
.faq-btn h2 {
  -webkit-user-select: none;
          user-select: none;
  font-weight: 500;
  font-size: 25px;
  text-transform: uppercase;
  line-height: 34px;
  transition: all 0.4s ease-in-out;
}
.faq-btn svg {
  font-size: 25px;
  transition: all 0.4s ease-in-out;
}
.faq-answer {
  max-height: 0px;
  text-align: left;
  transition: all 0.7s ease-in-out;
  width: 100%;
}
.faq-answer p {
  text-align: left;
  padding-bottom: 18px;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  color: white;
}
.faq-btn:hover,
.active-faq-btn {
  color: var(--light-green);
}
.active-answer {
  max-height: 6000px;
}
@media screen and (max-width: 650px) {
  .faq-btn h2 {
    font-size: 14px;
    line-height: 17px;
  }
  .faq-answer p {
    font-size: 12px;
    line-height: 18px;
  }
}

.feat-wrap {
  padding: 100px 0px;
  background-image: url(/static/media/feature-bg.bd6c2577.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 80px;
  position: relative;
  isolation: isolate;
}
.feat-wrap::before {
  content: "";
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -4;
  opacity: 0.8;
}
.feature-div {
  padding-inline: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: var(--max-width);
  width: 100%;
}
.feature-row {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 40px;
  gap: 40px;
  width: 100%;
  margin-top: 60px;
}
.feature-row img {
  width: 600px;
  object-fit: contain;
}
.feature-row ul {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 20px;
  gap: 20px;
  flex-direction: column;
  padding-left: 10px;
}
.feature-row ul li {
  color: white;
  font-size: 20px;
}
@media screen and (max-width: 1100px) {
  .feat-wrap {
    padding: 70px 0px;
    margin-top: 50px;
  }
  .feature-row {
    flex-direction: column;
    grid-gap: 40px;
    gap: 40px;
    margin-top: 40px;
  }
  .feature-row ul {
    align-items: center;
    text-align: center;
  }
  .feature-row img {
    width: 100%;
    max-width: 450px;
  }
}
@media screen and (max-width: 650px) {
  .feat-wrap {
    padding: 40px 0px;
    margin-top: 30px;
  }
  .feature-card {
    padding: 30px 20px;
  }
  .feature-card img {
    height: 60px;
  }
  .feature-card h3 {
    font-size: 24px;
    line-height: 29px;
  }
  .feature-card p,
  .feature-row ul li {
    font-size: 14px;
    line-height: 21px;
  }
}

.hero-wrap {
  background-image: url(/static/media/bg2.dbd6c466.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* margin-bottom: 35px; */
  position: relative;
  isolation: isolate;
}
.hero-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: -1;
}
.hero-div {
  width: 100%;
  padding: 100px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  min-height: 100vh;
}
.hero-div > img {
  max-width: 350.87px;
  width: 100%;
  object-fit: contain;
}
.hero-div > p {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  max-width: 800px;
}
.hero-div > p:last-child {
  font-size: 16px;
}
.hero-div > p span {
  color: var(--light-green);
  font-size: 28px;
  font-weight: bold;
  line-height: 1.4;
}
.btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 10px;
}
.social-hero {
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  display: flex;
  margin-top: 10px;
}
.social-hero > a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: white;
  transition: all 0.7s ease-in-out;
}
.social-hero > a:hover {
  color: var(--light-green);
}
/* @media screen and (max-width: 1161px) {
  .hero-wrap {
    margin-bottom: 30px;
  }
} */
@media screen and (max-width: 650px) {
  .hero-div > img {
    max-width: 190px;
  }
  .hero-div > p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 610px) {
  .hero-div .btn-div {
    flex-direction: column;
    width: 100%;
  }
  .hero-div .btn-div button,
  .hero-div .btn-div a {
    width: 100% !important;
  }
}

.wrapper-character {
  overflow: hidden;
  width: 100%;
  height: auto;
  position: relative;
  max-width: 500px;
}

.wrapper-character video {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.nft-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1700px;
  flex-direction: column;
  margin-top: 80px;
  padding-inline: 20px;
}
.title {
  color: var(--light-green);
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  text-transform: uppercase;
}
.sub {
  color: white;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin-top: 10px;
  max-width: 1000px;
}
.sub a {
  color: white;
  text-decoration: none;
}
.nft-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  grid-template-rows: 1fr;
  grid-row-gap: 0px;
  row-gap: 0px;
  grid-column-gap: 50px;
  -webkit-column-gap: 50px;
          column-gap: 50px;
  margin-top: 70px;
  place-items: center;
}
@media screen and (max-width: 1285px) {
  .nft-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, auto);
    place-items: center;
    grid-row-gap: 60px;
    row-gap: 60px;
  }
}
@media screen and (max-width: 961px) {
  .nft-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
  }
}
@media screen and (max-width: 650px) {
  .nft-div {
    margin-top: 50px;
  }
  .title {
    font-size: 24px;
    line-height: 29px;
  }
  .sub {
    font-size: 14px;
    line-height: 21px;
  }
  .nft-grid {
    grid-row-gap: 30px;
    row-gap: 30px;
  }
}

.roadmap-wrapp {
  padding: 180px 20px;
  /* margin-top: 100px; */
  align-items: flex-end;
  background-image: url(/static/media/roadmap-bg.66cd0ff9.webp);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  isolation: isolate;
}
.roadmap-wrapp::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.8;
  z-index: -1;
}
.road-line {
  position: absolute;
  left: 0;
  object-fit: cover;
  height: 40px;
  width: 100%;
}
.road-line1 {
  top: 0%;
}
.road-line2 {
  bottom: 0;
}
.roadmap-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
  max-width: calc(100% - 150px);
  overflow-x: auto;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
.roadmap-div {
  scrollbar-width: thin;
  scrollbar-color: #df9200 #0c0a08;
  padding-bottom: 20px;
}

/* Chrome, Edge, and Safari */
.roadmap-div::-webkit-scrollbar {
  width: 10px;
  height: 7px;
}

.roadmap-div::-webkit-scrollbar-track {
  background: #0c0a08;
}

.roadmap-div::-webkit-scrollbar-thumb {
  background-color: var(--light-green);
  border-radius: 8px;
  border: 0px none #ffffff;
}
.roadmap-grid {
  display: grid;
  grid-template-columns: 30px 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
  width: 100%;
  grid-row-gap: 120px;
  row-gap: 120px;
  max-width: 100%;
  grid-column-gap: 120px;
  -webkit-column-gap: 120px;
          column-gap: 120px;
  margin-top: 100px;
}
.roadmap-grid .line-roadmap {
  height: 3px;
  width: 100%;
  background-color: rgba(223, 146, 0, 0.2);
  grid-column: 1/5;
  grid-row: 2/3;
  position: relative;
}
.line-roadmap::before {
  content: "";
  position: absolute;
  left: 2px;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
  width: 14px;
  height: 14px;
  z-index: 20;
  background-color: var(--light-green);
}
.active-line-road {
  background-color: var(--light-green);
  width: 70%;
  height: 3px;
  grid-column: 1/3;
  grid-row: 2/3;
  margin-left: 10px;
  position: relative;
}
.pointer {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  max-width: 100px;
  opacity: 0.9;
}
.road-box {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 50px;
  height: -webkit-max-content;
  height: max-content;
  min-width: 527px;
  background: rgba(255, 255, 255, 0.1);
}
.road-box ul {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  list-style-position: inside;
}
.road-box li {
  color: white;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
}
.roadbox-1 {
  grid-column: 2/3;
  grid-row: 3/4;
}
.roadbox-2 {
  grid-column: 3/4;
  grid-row: 3/4;
}
.roadbox-3 {
  grid-column: 4/5;
  grid-row: 3/4;
}

.roadmap-grid > h3 {
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  color: var(--light-green);
  justify-self: center;
  align-self: center;
  opacity: 0.4;
}
.roadmap-grid .active-title {
  opacity: 1;
}
.road-title-1 {
  grid-column: 2/3;
  grid-row: 1/2;
}
.road-title-2 {
  grid-column: 3/4;
  grid-row: 1/2;
}
.road-title-3 {
  grid-column: 4/5;
  grid-row: 1/2;
}
@media screen and (max-width: 900px) {
  .roadmap-wrapp {
    /* margin-top: 50px; */
    padding: 150px 20px;
  }
  .road-line {
    height: 30px;
  }
  .roadmap-div {
    max-width: 100%;
    align-items: center;
  }
  .roadmap-div > h2 {
    font-size: 32px;
    line-height: 38px;
  }

  .roadmap-grid {
    margin-top: 50px;
    grid-row-gap: 100px;
    row-gap: 100px;
    grid-template-columns: 100% 1fr 1fr;
  }
  .active-line-road {
    grid-column: 1/2;
    width: 40%;
  }
  .pointer {
    left: 100%;
    width: 80px;
    object-fit: contain;
  }
  .road-title-1 {
    grid-column: 1/2;
  }
  .road-box {
    justify-self: center;
    align-self: center;
    padding: 20px;
    min-width: 350px;
  }
  .roadbox-1,
  .road-title-1 {
    grid-column: 1/2;
  }
  .roadbox-2,
  .road-title-2 {
    grid-column: 2/3;
  }
  .roadbox-3,
  .road-title-3 {
    grid-column: 3/4;
  }
  .road-box ul li {
    font-size: 18px;
    line-height: 21px;
  }
  .roadmap-grid > h3 {
    font-size: 24px;
    line-height: 29px;
  }
}

.trailer-wrapp > video {
  width: 100%;
  object-fit: cover;
  max-height: 730px;
}
.trailer-wrapp {
  position: relative;
  /* margin-top: 35px; */
  isolation: isolate;
}
.trailer-wrapp:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 2;
}
.trailer-div {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  width: 100%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 944px;
  color: white;
  z-index: 3;
}
.trailer-div p {
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
}
/* @media screen and (max-width: 1161px) {
  .trailer-wrapp {
    margin-top: 30px;
  }
} */
@media screen and (max-width: 1043px) {
  .trailer-div p {
    font-size: 18px;
    line-height: 24px;
  }
  .trailer-div {
    padding-inline: 20px;
  }
  .trailer-wrapp > video {
    min-height: 420px;
  }
}

.bvv-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: var(--max-width);
  grid-gap: 0px;
  gap: 0px;
  margin-top: 40px;
  padding-inline: 20px;
}
.bvv-logo {
  width: 100%;
  max-width: 400px;
}
.bvv-div p {
  color: white;
  font-size: 18px;
  text-align: center;
  max-width: 800px;
  margin-top: 10px;
  margin-bottom: 25px;
}
.bvv-div a {
  text-decoration: none;
  margin-bottom: 40px;
}
.bvv-cover {
  width: 100%;
  object-fit: contain;
}
@media screen and (max-width: 540px) {
  .bvv-div p {
    font-size: 14px;
  }
  .bvv-logo {
    max-width: 300px;
  }
}

